import React from 'react';
import PropTypes from 'prop-types';
import LoginFormV2 from '../LoginFormV2';
import LogInBackgroundImage from '../../../images/log-in-background-image.png';
import OtpBackgroundImage from '../../../images/otp-background-image.png';

const MainLoginComponentV2 = ({ 
  text,
  logoUrl,
  supportEmail,
  supportPhone,
  supportContact, 
  email,
  errors,
  otp,
  otpError,
  showOtp,
  showOtpRequest,
  handleOtpChange,
  onOtpSubmit,
  onEmailChange,
  onSubmit,
  requestOtp,
}) => {
  const copies = showOtp ? {
    title: "User verification",
    descriptionFirstLine: `To access the desired content, we need to authenticate you. Please enter the code we just sent to ${email}`,
  } : {
    title: "Welcome!",
    descriptionFirstLine: "To continue, request a one-time passcode.",
    descriptionSecondLine: "This code will be sent to your email.",
  };

  return (
    <div className="login-page-v2">
      <div className="login-page-v2__container">
        <div className="login-page-v2__background-image-container">
          <img
            src={showOtp ? OtpBackgroundImage : LogInBackgroundImage}
            className="login-page-v2__background-image"
          />
          <img
            src={logoUrl}
            className="login-page-v2__logo"
            alt="Login logo"
          />
        </div>
        <div className="login-page-v2__form-container">
          <div className="login-page-v2__form-wrapper">
            <h2 className="login-page-v2__eyebrow">Sign in</h2>
            <div className="login-page-v2__form-inner-wrapper">
              <div className="login-page-v2__form-header-container">
                <h3 className="login-page-v2__subtitle">{copies.title}</h3>
                <div>
                  <p className="login-page-v2__description">{copies.descriptionFirstLine}</p>
                  {copies.descriptionSecondLine && (
                    <p className="login-page-v2__description">{copies.descriptionSecondLine}</p>
                  )}
                </div>
              </div>
              <LoginFormV2
                email={email}
                errors={errors}
                handleOtpChange={handleOtpChange}
                onEmailChange={onEmailChange}
                onSubmit={onSubmit}
                otp={otp}
                otpError={otpError}
                requestOtp={requestOtp}
                showOtp={showOtp}
                showOtpRequest={showOtpRequest}
                text={text}
                supportEmail={supportEmail}
                supportPhone={supportPhone}
                supportContact={supportContact}
                onOtpSubmit={onOtpSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MainLoginComponentV2.propTypes = {
  text: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  supportEmail: PropTypes.string.isRequired,
  supportPhone: PropTypes.string.isRequired,
  supportContact: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  errors: PropTypes.array,
  otp: PropTypes.string,
  otpError: PropTypes.string,
  showOtp: PropTypes.bool.isRequired,
  showOtpRequest: PropTypes.bool.isRequired,
  onOtpSubmit: PropTypes.func.isRequired,
  handleOtpChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestOtp: PropTypes.func.isRequired,
};

export default MainLoginComponentV2;
