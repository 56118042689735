import React from 'react';
import PropTypes from 'prop-types';
import WhyCoverwallet from '../../WhyCoverwallet';
import LoginForm from '../LoginForm';

const MainLoginComponent = ({ 
  text,
  subtitle,
  logoUrl,
  baseURL,
  authorDescription,
  whyCoverWalletText,
  enabledPoweredByCoverwalletLogo,
  oneTimePasswordEnabled,
  supportEmail,
  supportPhone,
  supportContact, 
  email,
  errors,
  loginRedirectionFlag,
  otp,
  otpError,
  password,
  showOtp,
  showOtpRequest,
  showPassword,
  otpSubtitle,
  cwStaticFilesUrl,
  displayForgotPassword,
  newPasswordPath,
  loginPageName,
  displayReturnToLogin,
  onReturnToLogin,
  handleOtpChange,
  onEmailChange,
  onPasswordChange,
  onSubmit,
  requestOtp,
  toggleLoginMethod,
  onForgotPasswordClick,
}) => {
  return (
    <div className="login-page">
        <div className="login-page__container">
          {!(showOtp && !showOtpRequest) && (
            <div className="login-page__info-section-container">
              <WhyCoverwallet
                authorDescription={
                  loginRedirectionFlag
                    ? "You will be redirected to /test-login"
                    : authorDescription
                }
                whyCoverWalletText={whyCoverWalletText}
              />
              {enabledPoweredByCoverwalletLogo && (
                <img
                  src={`${cwStaticFilesUrl}/logo-powered-by-cw.svg`}
                  className="login-page__info-section-logo"
                  alt="Powered by CoverWallet"
                />
              )}
            </div>
          )}
          <div className="login-page__form-container">
            <div className="login-page__form-wrapper">
              <div className="login-page__logo-container">
                <img
                  src={logoUrl}
                  className="login-page__logo"
                  alt="Login logo"
                />
              </div>
              <h3 className="login-page__subtitle">{subtitle}</h3>
              {(showPassword || showOtp) && !otpSubtitle && (
                <div className="login-page__email-container">
                  <button
                    className="login-page__email"
                    onClick={onReturnToLogin}
                  >
                    {email}
                  </button>
                </div>
              )}
              <LoginForm
                email={email}
                errors={errors}
                handleOtpChange={handleOtpChange}
                isOtpEnabled={oneTimePasswordEnabled}
                onEmailChange={onEmailChange}
                onPasswordChange={onPasswordChange}
                onSubmit={onSubmit}
                otp={otp}
                otpError={otpError}
                password={password}
                requestOtp={requestOtp}
                showOtp={showOtp}
                showOtpRequest={showOtpRequest}
                showPassword={showPassword}
                toggleLoginMethod={toggleLoginMethod}
                text={text}
                supportEmail={supportEmail}
                supportPhone={supportPhone}
                supportContact={supportContact}
              />
            </div>
            
            {displayForgotPassword && (
              <a
                onClick={onForgotPasswordClick}
                href={`${baseURL}${newPasswordPath}`}
                className="login-form__reset-password"
                data-tw-page={loginPageName}
              >
                Forgot password?
              </a>
            )}
            {displayReturnToLogin && (
              <a
                onClick={onReturnToLogin}
                className="login-form__return-to-login"
              >
                « Return to Log In
              </a>
            )}
          </div>
        </div>
      </div>
  );
};

MainLoginComponent.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  authorDescription: PropTypes.string.isRequired,
  whyCoverWalletText: PropTypes.array.isRequired,
  enabledPoweredByCoverwalletLogo: PropTypes.bool,
  oneTimePasswordEnabled: PropTypes.bool.isRequired,
  supportEmail: PropTypes.string.isRequired,
  supportPhone: PropTypes.string.isRequired,
  supportContact: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  errors: PropTypes.array,
  loginRedirectionFlag: PropTypes.bool.isRequired,
  otp: PropTypes.string,
  otpError: PropTypes.string,
  password: PropTypes.string,
  showOtp: PropTypes.bool.isRequired,
  showOtpRequest: PropTypes.bool.isRequired,
  showPassword: PropTypes.bool.isRequired,
  otpSubtitle: PropTypes.string,
  cwStaticFilesUrl: PropTypes.string.isRequired,
  baseURL: PropTypes.string.isRequired,
  displayForgotPassword: PropTypes.bool.isRequired,
  newPasswordPath: PropTypes.string,
  loginPageName: PropTypes.string,
  displayReturnToLogin: PropTypes.bool.isRequired,
  onReturnToLogin: PropTypes.func.isRequired,
  handleOtpChange: PropTypes.func.isRequired,
  onEmailChange: PropTypes.func.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestOtp: PropTypes.func.isRequired,
  toggleLoginMethod: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
};

export default MainLoginComponent;
