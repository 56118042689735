import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import cs from "classnames";
import OtpInput from "react-otp-input";

import V2WarningIcon from "../../../icons/V2WarningIcon";
import V2ArrowRightIcon from "../../../icons/V2ArrowRightIcon";
import TrackingHelper, { trackingEvents } from "../../../lib/TrackingHelper";
import OtpContactSupportV2 from "./OtpContactSupportV2";
import V2ExclamationCircleIcon from "../../../icons/V2ExclamationCircleIcon";

const RESEND_TIMEOUT = 30_000;
const MAX_RESEND_ATTEMPTS = 3;

const OtpV2 = ({
  error,
  onChange,
  onSubmit,
  requestOtp,
  value,
  supportEmail,
  supportPhone,
  supportContact,
}) => {
  const [canResendCode, setCanResendCode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(RESEND_TIMEOUT / 1000);
  const [resendAttempts, setResendAttempts] = useState(0);
  const isMaxResendAttempts = resendAttempts === MAX_RESEND_ATTEMPTS;
  const resendBannerText = isMaxResendAttempts 
    ? `You have reached the maximum number of attempts (${MAX_RESEND_ATTEMPTS}).` 
    : `Request a code again in ${remainingTime} seconds`;

  useEffect(() => {
    let timer;
    let interval;

    if (!canResendCode) {
      timer = setTimeout(() => {
        setCanResendCode(true);
        clearInterval(interval);
        setRemainingTime(0);
      }, RESEND_TIMEOUT);
      
      interval = setInterval(() => {
        setRemainingTime(prevTime => {
          if (prevTime < 1) {
            clearInterval(interval);
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    }

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, [canResendCode]);

  const handleResendOtp = () => {
    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      buttonName: "request_otp_again",
      buttonType: "click",
      label: "Send code again",
    });
    
    if (canResendCode) {
      requestOtp({ hideLoader: true });
      setCanResendCode(false);
      setResendAttempts(resendAttempts + 1);
      setRemainingTime(RESEND_TIMEOUT / 1000);
    }
  };

  return (
    <div className="otp-v2">
      <div className="otp-v2__input-form">
        <div className="otp-v2__input-container">
          <OtpInput
            containerStyle={"otp-v2__container"}
            id="otp-input"
            inputStyle={cs("otp-v2__input", {"otp-v2__input__error": error })}
            numInputs={6}
            onChange={onChange}
            renderInput={(props) => (
              <input {...props} style={{}} />
            )}
            shouldAutoFocus={true}
            value={value}
          />
          {error && (
            <div className="login-form-v2__validation-message-wrapper">
              <div className="login-form-v2__validation-message-icon">
                <V2WarningIcon />
              </div>
              <p className="login-form-v2__validation-message-text">{error}</p>
            </div>
          )}
        </div>
        <div className={"login-form-v2__submit-container"}>
          <button
            data-test="otp-submit-input"
            data-tw-page="OTP Page"
            data-tw-label="Submit OTP"
            type="button"
            className="login-form-v2__submit"
            onClick={() => onSubmit(value)}
          >
            <div className="login-form-v2__submit-div">
              Sign in
              <div className="login-form-v2__submit-icon">
                <V2ArrowRightIcon />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div className="otp-v2__info">
        <a
          href="#"
          onClick={canResendCode ? handleResendOtp : () => {}}
          className={cs("otp-v2__info__resend-link", {
            "otp-v2__info__resend-link--disabled": !canResendCode || isMaxResendAttempts,
          })}
        >
          Resend code
        </a>
        {(remainingTime > 0 || isMaxResendAttempts) && (
          <div className="otp-v2__info__resend-banner-wrapper">
            <div className="otp-v2__info__resend-banner-icon">
              <V2ExclamationCircleIcon />
            </div>
            <p className="otp-v2__info__resend-banner-text">{resendBannerText}</p>
          </div>
        )}
        <OtpContactSupportV2
          supportEmail={supportEmail}
          supportPhone={supportPhone}
          supportContact={supportContact}
        />
      </div>
    </div>
  );
};

OtpV2.propTypes = {
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  requestOtp: PropTypes.func.isRequired,
  value: PropTypes.string,
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
};

export default OtpV2;
