// ***** DELETE HELPER AFTER V2 LOGIN IS COMPLETED *****
const getUserCookie = (cookieName) => {
  if (typeof document === 'undefined') return null;

  const name = `${cookieName}=`;

  if (document?.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
  }

  return null;
};

export {
  getUserCookie,
};
// **************************************************************