import * as commonPasswords from "../../../lib/common_passwords.json";
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const EMAIL_FORMAT_ERROR = "Please enter a valid email";
const NO_EMAIL_ERROR = "Please enter an email to continue";

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_PASSWORD_LENGTH = 72;
export const LETTERS_AND_NUMBERS_AND_SPECIAL_CHAR_REGEX =
  /^(?=.*[A-z])(?=.*[0-9])[A-z0-9!?@#$%^&*~"'`´:;(){}|+-<=>\[\]\-_,./\\\s]+$/;
export const MIN_PASSWORD_ERROR = `Password needs to be at least ${MIN_PASSWORD_LENGTH} characters long`;
export const MAX_PASSWORD_ERROR = `Password is too long (maximum is ${MAX_PASSWORD_LENGTH} characters)`;
export const LETTERS_AND_NUMBERS_ERROR =
  "Password must be a combination of numbers and letters";
export const COMMON_PASSWORD_ERROR = "Password cannot be a common password";

const COMPANY_ERROR = "Please fill all the information";

export const WRONG_PASSWORD_ERROR =
  'Invalid email or password. Please try again or click on "Forgot Password" below.';
export const ACCOUNT_MAYBE_BLOCKED =
  "Your account may have been blocked due to excessive login attempts. Please, check your email to proceed";

export const EMAIL_REGISTERED_ERROR =
  "An account with this email already exists";
export const NEEDS_PASSWORD_ERROR = "The account needs to set a password";

export const REQUEST_OTP_ERROR =
  "Something went wrong sending the OTP. Please try again in a few minutes.";
export const WRONG_OTP_ERROR = "Invalid OTP. Please try again";
export const WRONG_OTP_ERROR_V2 = "Invalid code";
export const INVALID_OTP_FORMAT_ERROR = "Please, enter the code sent to your email";

export const validateEmailFormat = (email) => {
  if (!email) {
    return [NO_EMAIL_ERROR];
  }

  if (!EMAIL_REGEX.test(email)) {
    return [EMAIL_FORMAT_ERROR];
  }

  return [];
};

export const validatePassword = (password) => {
  const errors = [];

  if (password.length < MIN_PASSWORD_LENGTH) {
    errors.push(MIN_PASSWORD_ERROR);
  }

  if (password.length > MAX_PASSWORD_LENGTH) {
    errors.push(MAX_PASSWORD_ERROR);
  }

  if (!password.match(LETTERS_AND_NUMBERS_AND_SPECIAL_CHAR_REGEX)) {
    errors.push(LETTERS_AND_NUMBERS_ERROR);
  }

  if (commonPasswords.default.includes(password)) {
    errors.push(COMMON_PASSWORD_ERROR);
  }

  return errors;
};

export const validateCompanyInfo = ({
  firstName,
  lastName,
  companyName,
  address,
  zipCode,
  city,
  state,
}) => {
  if (
    firstName &&
    lastName &&
    companyName &&
    address &&
    zipCode &&
    city &&
    state
  ) {
    return [];
  }

  return [COMPANY_ERROR];
};
