import React, { Component } from "react";
import PropTypes from "prop-types";
import TrackingHelper, { trackingEvents } from "../lib/TrackingHelper";
import storage from "../lib/LocalStorage";
import Loader from "../components/common/Loader";
import {
  ACCOUNT_MAYBE_BLOCKED,
  REQUEST_OTP_ERROR,
  validateEmailFormat,
  WRONG_OTP_ERROR,
  WRONG_OTP_ERROR_V2,
  INVALID_OTP_FORMAT_ERROR,
  WRONG_PASSWORD_ERROR,
} from "../lib/validations";
import { UserService } from "../services/UsersServices";
import { navigateTo } from "../lib/NavigationHelper";
import { maskEmail } from "../utils/helpers/maskEmail";
import { getUserCookie } from "../utils/helpers/getCookie";
import MainLoginComponent from "../components/login/main-login-component/MainLoginComponent";
import MainLoginComponentV2 from "../components/login-v2/main-login-component-v2/MainLoginComponentV2";

const LOGIN_TYPES = {
  OTP: "otp",
  PASSWORD: "password",
};
const STORAGE_EMAIL_KEY = "user_email";
const NEW_PASSWORD_PATH = "/users/password/new";
const LOGIN_PAGE_NAME = "Login Page";
const FAILED_ATTEMPTS_BEFORE_WARNING = 5;
const WRONG_PASSWORD_TRACKING_LABEL = "Invalid email or password";
const ACCOUNT_MAYBE_BLOCKED_TRACKING_LABEL = "Account may have been blocked";

const initialState = {
  otp: "",
  otpError: "",
  otpSubtitle: "",
  password: "",
  showOtp: false,
  requestingOtp: false,
  redirectingOnSuccessOtp: false,
  showOtpRequest: false,
  showPassword: false,
  failedAttempts: 0,
  displayReturnForLoginOtp: true,
  loginRedirectionFlag: false,
};

class LoginPage extends Component {
  constructor(props) {
    super(props);
    const { loginType, loginHint, shortOtpSubtitle, baseURL } = props;
    this.userService = new UserService({ baseURL });
    this.state = {
      ...initialState,
      email: loginHint || "",
      errors: this.props.errorMessages || [],
    };

    if (loginType === LOGIN_TYPES.OTP && loginHint) {
      this.state.requestingOtp = true;
      this.state.otpSubtitle = `${shortOtpSubtitle} ${maskEmail(loginHint)}`;

      // Remove the login hint from the URL when the user directly lands on the OTP input page
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  }

  componentDidMount() {
    this.setEmailStateFromLocalStorage();
    window.analytics.ready(() => {
      TrackingHelper.isFlagOn("login-redirection-page")
        .then((value) => {
          this.setState({
            loginRedirectionFlag: value.key === "on",
          })
        }
        )
        .catch(() => {
          this.setState({
            loginRedirectionFlag: false,
          })
        }
        );
    });

    if (this.props.loginType === LOGIN_TYPES.OTP && this.state.email) {
      this.setState({
        displayReturnForLoginOtp: false,
      });
      this.onEmailSubmit();
      this.requestOtp({ hideLoader: true });
    }
  }

  onEmailChange = (event) => {
    const { value } = event.target;
    this.setState({
      email: value,
    });
  };

  onPasswordChange = (event) => {
    const { value } = event.target;
    this.setState({ password: value });
  };

  onReturnToLogin = (event) => {
    event.preventDefault();

    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      buttonName: "return_to_login",
      buttonType: "click",
      label: "Return to Log In",
      page: LOGIN_PAGE_NAME,
    });

    this.setState(initialState);
  };

  onSubmit = (event) => {
    const { showPassword } = this.state;
    const { isV2Appmode } = this.props;
    event.preventDefault();

    if (showPassword) {
      this.onPasswordSubmit();
    } else {
      this.onEmailSubmit();
      if(!isV2Appmode || !getUserCookie("v2-ui-enabled")) {
        TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
          buttonName: "continue",
          buttonType: "click",
          label: "Continue",
          page: LOGIN_PAGE_NAME,
        });
      }
    }
  };

  toggleLoginMethod = (event) => {
    event.preventDefault();
    const { showOtp } = this.state;

    if (showOtp) {
      this.setState({
        showOtp: false,
        showOtpRequest: false,
        otpError: "",
        showPassword: true,
      });
      TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
        buttonName: "use_password_link",
        buttonType: "click",
        label: "Use Password",
        page: LOGIN_PAGE_NAME,
      });
    } else {
      this.setState({
        showOtp: true,
        showOtpRequest: true,
        showPassword: false,
      });
      TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
        buttonName: "request_otp_link",
        buttonType: "click",
        label: "Request a one-time password",
        page: LOGIN_PAGE_NAME,
      });
    }
  };

  onEmailSubmit() {
    const { email } = this.state;
    const { oneTimePasswordEnabled } = this.props;
    const errors = validateEmailFormat(email);
    if (errors.length) {
      this.setState({ errors });
    } else {
      window.trackingWallet.identify(email);
      if (oneTimePasswordEnabled) {
        this.setState({
          otpError: "",
          errors: [],
          showOtpRequest: true,
          showOtp: true,
        });
      } else {
        this.setState({
          errors: [],
          showPassword: true,
        });
      }
    }
  }

  requestOtp = ({ hideLoader = false }) => {
    const { email } = this.state;
    // Do not show loader if retrying
    if (!hideLoader) {
      this.setState({
        requestingOtp: true,
      });
    }

    this.userService
      .requestOtp(email)
      .then(() => {
        TrackingHelper.track(trackingEvents.OTP_SENT, {
          email: email,
        });
        this.setState({
          showOtp: true,
          showOtpRequest: false,
          requestingOtp: false,
        });
      })
      .catch(() => {
        TrackingHelper.track(trackingEvents.OTP_SENDING_FAILED, {
          email: email,
        });
        this.setState({
          ...initialState,
          errors: [REQUEST_OTP_ERROR],
        });
      });
  };

  handleOtpChange = (value) => {
    const { isV2Appmode } = this.props;
    this.setState({
      otp: value,
    });

    if (value.length === 6 && !(isV2Appmode && getUserCookie("v2-ui-enabled")) ) {
      this.onOtpSubmit(value);
    }
  };

  onOtpSubmit = (value) => {
    const { email } = this.state;
    const { isV2Appmode } = this.props;

    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      buttonName: "log_in",
      label: "Log In",
      page: LOGIN_PAGE_NAME,
    });

    this.userService
      .loginOtp({ email, otp: value })
      .then((response) => {
        TrackingHelper.track(trackingEvents.OTP_VALIDATION_RESULT, {
          output: "correct",
          passcode: value,
        });
        this.onSuccessfulOtpLogin(response.headers.location);
      })
      .catch(() => {
        TrackingHelper.track(trackingEvents.OTP_VALIDATION_RESULT, {
          output: "incorrect",
          passcode: value,
        });

        if(value.length < 6) {
          this.setState({
            otpError: INVALID_OTP_FORMAT_ERROR,
            submittingOtp: false,
          });
        } else {
          this.setState({
            otp: "",
            otpError: isV2Appmode ? WRONG_OTP_ERROR_V2 : WRONG_OTP_ERROR,
            submittingOtp: false,
          });
        }
      });
  };

  onSuccessfulOtpLogin = (successPath) => {
    this.setState({
      redirectingOnSuccessOtp: true,
    });
    TrackingHelper.track(trackingEvents.USER_LOGGED_IN, {
      url: window.location.href,
      loginMethod: "otp",
    });
    setTimeout(() => {
      navigateTo(successPath);
    }, 1_000);
  };

  onPasswordSubmit() {
    const { email, password } = this.state;

    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      buttonName: "log_in_with_password",
      buttonType: "click",
      label: "Log In",
      page: LOGIN_PAGE_NAME,
    });

    this.userService
      .loginUser({ email, password })
      .then((response) => {
        TrackingHelper.track(trackingEvents.USER_LOGGED_IN, {
          url: window.location.href,
          loginMethod: "password",
        });
        navigateTo(response.headers.location);
      })
      .catch(() => {
        this.handleFailedLogin();
      });
  }

  handleFailedLogin() {
    let failedAttempts = this.state.failedAttempts + 1;
    let errorMessage = WRONG_PASSWORD_ERROR;
    let trackingLabel = WRONG_PASSWORD_TRACKING_LABEL;
    if (failedAttempts === FAILED_ATTEMPTS_BEFORE_WARNING) {
      failedAttempts = 0;
      errorMessage = ACCOUNT_MAYBE_BLOCKED;
      trackingLabel = ACCOUNT_MAYBE_BLOCKED_TRACKING_LABEL;
    }

    TrackingHelper.track(trackingEvents.LOADED_A_NOTIFICATION, {
      email: this.state.email,
      type: "error",
      label: trackingLabel,
    });
    this.setState({
      errors: [errorMessage],
      failedAttempts: failedAttempts,
    });
  }

  onForgotPasswordClick = () => {
    TrackingHelper.track(trackingEvents.BUTTON_CLICKED, {
      to: NEW_PASSWORD_PATH,
      page: LOGIN_PAGE_NAME,
      label: "Forgot password?",
      buttonName: "forgot_password",
      buttonType: "click",
      url: window.location.href,
    });
  };

  setEmailStateFromLocalStorage() {
    const { email } = this.state;
    const storedEmail = storage.get(STORAGE_EMAIL_KEY);
    if (storedEmail && !email) {
      this.setState({
        email: storedEmail,
      });
    }
  }

  render() {
    const {
      text,
      logoUrl,
      forgotPasswordDisabled,
      authorDescription,
      whyCoverWalletText,
      enabledPoweredByCoverwalletLogo,
      oneTimePasswordEnabled,
      supportEmail,
      supportPhone,
      supportContact,
      isV2Appmode,
    } = this.props;
    const {
      email,
      errors,
      loginRedirectionFlag,
      otp,
      otpError,
      password,
      requestingOtp,
      redirectingOnSuccessOtp,
      showOtp,
      showOtpRequest,
      showPassword,
      otpSubtitle,
      displayReturnForLoginOtp,
    } = this.state;

    const subtitle = otpSubtitle || "Welcome!";
    const displayForgotPassword =
      !forgotPasswordDisabled && !showOtp && !showOtpRequest;
    const displayReturnToLogin =
      !displayForgotPassword &&
      showOtp &&
      !showOtpRequest &&
      displayReturnForLoginOtp;

    if (requestingOtp || redirectingOnSuccessOtp) {
      const title = redirectingOnSuccessOtp ? "Thank you!" : null;
      const description = redirectingOnSuccessOtp
        ? "Redirecting you to your destination"
        : "Sending code...";
      return (
        <div className="login-page__loader-wrapper">
          <Loader title={title} description={description} />
        </div>
      );
    }

    // ***** REMOVE COOKIE CONDITION AFTER V2 LOGIN IS COMPLETED *****
    const displayV2Component = isV2Appmode && getUserCookie("v2-ui-enabled");
    // ***************************************************************

    const mainComponentProps = {
      text,
      logoUrl,
      supportEmail,
      supportPhone,
      supportContact,
      email,
      errors,
      otp,
      otpError,
      showOtp,
      showOtpRequest,
      handleOtpChange: this.handleOtpChange,
      onEmailChange: this.onEmailChange,
      onSubmit: this.onSubmit,
      requestOtp: this.requestOtp,
    };
    
    const v1ComponentProps = {
      ...mainComponentProps,
      subtitle,
      cwStaticFilesUrl: this.props.cwStaticFilesUrl,
      baseURL: this.props.baseURL,
      authorDescription,
      whyCoverWalletText,
      enabledPoweredByCoverwalletLogo,
      oneTimePasswordEnabled,
      loginRedirectionFlag,
      password,
      otpSubtitle,
      displayForgotPassword,
      newPasswordPath: NEW_PASSWORD_PATH,
      loginPageName: LOGIN_PAGE_NAME,
      showPassword,
      displayReturnToLogin,
      onReturnToLogin: this.onReturnToLogin,
      onPasswordChange: this.onPasswordChange,
      toggleLoginMethod: this.toggleLoginMethod,
      onForgotPasswordClick: this.onForgotPasswordClick,
    }

    const v2ComponentProps = {
      ...mainComponentProps,
      onOtpSubmit: this.onOtpSubmit,
    }

    return (
      displayV2Component 
      ? <MainLoginComponentV2 {...v2ComponentProps} />
      : <MainLoginComponent {...v1ComponentProps} />
    );
  }
}

LoginPage.propTypes = {
  authorDescription: PropTypes.string.isRequired,
  baseURL: PropTypes.string.isRequired,
  cwStaticFilesUrl: PropTypes.string,
  enabledPoweredByCoverwalletLogo: PropTypes.bool,
  forgotPasswordDisabled: PropTypes.bool,
  loginHint: PropTypes.string,
  loginType: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  oneTimePasswordEnabled: PropTypes.bool,
  text: PropTypes.string,
  whyCoverWalletText: PropTypes.array.isRequired,
  supportEmail: PropTypes.string,
  supportPhone: PropTypes.string,
  supportContact: PropTypes.string.isRequired,
  isV2Appmode: PropTypes.bool.isRequired,
};
export default LoginPage;
